const axios = require("axios");
const LeaderBoardServices = {
  getAllCompetitorsOfCategory: (category) => {
    return axios.get(
      `/api/competitors/scoreboard?category=${category}`
    );
  },
  getAllCompetitorsOfDiscipline: (category, discipline) => {
    return axios.get(
      `/api/competitors?category=${category}&discipline=${discipline}`
    );
  },
};
export default LeaderBoardServices;
