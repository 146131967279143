const axios = require("axios");
const CategoryService = {
  getAllCategories: () => {
    return axios.get(`/api/categories`);
  },
  getAllDiscipline: () => {
    return axios.get(`/api/disciplines`);
  },
};
export default CategoryService;
